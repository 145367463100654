<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact>
      Procedimientos y Medicamentos
      <template v-slot:button>
        <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
            class="btn btn-success btn-sm shadow-sm mx-2">
                Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
        </router-link>
    </template>
    </page-header-compact>
    <br />
    <div class="container-xl px-4">
      <div class="card ">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <br />
      <wizard
        :steps="this.steps.length"
        @prev="prev($event)"
        @next="next($event)"
        :current_step="current"
      >
        <template #tabs>
          <wizard-tab
            title="Medicamentos"
            step="1"
            active="true"
            id="step1"
            v-on:tab-click="next({ prev: current, current: 1 })"
          ></wizard-tab>
          <wizard-tab
            title="Procedimientos"
            step="2"
            id="step2"
            v-on:tab-click="next({ prev: current, current: 2 })"
          ></wizard-tab>
          
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                :is="currentComponent"
                :ref="currentComponent"
                v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>

import Wizard from "../../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../../components/common/utilities/Wizard/WizardTabPane";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../../services/historiaClinicaService";
import procedimientos from "./procedimientos";
import medicamentos from "./medicamentos";
import modalInformacionUsuario from "../informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../informacionUsuario/cardInformacionUsuario";
export default {
  components: {
    PageHeaderCompact,
    WizardTabPane,
    WizardTab,
    Wizard,
    procedimientos,
    medicamentos,
    modalInformacionUsuario,
    cardInformacionUsuario
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      idTipoHistoria : '',
      usuario: {},
      current: 1,
      checkpoint : 1,
      steps: [
        { stepId: "#step1", ref: "medicamentos", validate: false },
        { stepId: "#step2", ref: "procedimientos", validate: false },
      ],
    };
  },
  methods: {
    prev(step) {

      this.current = step.current;
      this.activeTab();
      
    },
    next(step) {
      
      if(step.prev <= this.checkpoint && step.current-1 > step.prev){
        this.activeTab();
        console.log(step.prev);
        return;
      }
      
      const refComponent = this.steps[step.prev - 1].ref;
      
      if (typeof this.$refs[refComponent].save !== "undefined") {
        this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if(step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      
      const response = await historiaClinicaService.show(id);
      
      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;

      this.idTipoHistoria = response.data.id_tipo_historia;

      this.activeTab()

    },
    activeTab(){
      document.querySelectorAll(`a.active`).forEach(a =>{ a.classList.remove('active') });
      document.querySelector(`a[href='${this.steps[this.current - 1].stepId}']`).classList.add("active");
    }
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {

      if (this.currentComponent === "procedimientos") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }

      if (this.currentComponent === "medicamentos") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }

      return {}
    },
    currentComponent() {
      let components = [
          "medicamentos",
        "procedimientos",
        
      ];

      return components[this.current - 1];
    },
  },
}
</script>

<style scoped></style>
