import Service from "./Service";
const baseurl = '/api/orden-clinica';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showOrdenByHc(id_historia) {
        return Service.get(`${baseurl}/show-orden-hc/${id_historia}`);
    },
    showOrdenById(id) {
        return Service.get(`${baseurl}/show-orden-byId/${id}`);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    showIdHistoriaAndType(params = {}) {
        return Service.get(`${baseurl}/show-id-type`,  {
            params : params
        });
    },
    showOrdenByHcAndType(params = {}) {
        return Service.get(`${baseurl}/show-orden-hc-and-type`,  {
            params : params
        });
    },
    showProcedimientosPorPrograma(params = {}) {
        return Service.get(`${baseurl}/examenes-por-programa`,  {
            params : params
        });
    }
}