<template>
    <main>
        <div class="mt-4" v-for="(orden,index) in ordenes" :key="index" >
            <div class="card-header bg-ligth">
                <div class="row">
                    <div class="col-6 ">
                        #{{index+1}} Orden No. {{ orden.id }} 
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        {{orden.created_at | formatDateTime}} 
                        <button @click="imprimir(orden.id);" class="btn btn-outline-info lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-print fa-fw"></i> Imprimir
                        </button>
                    </div>
                </div>
            </div>
            <div v-for="item in orden.items" :key="item.id">
                <div class="card-body" >
                    <h6>Medicamento: <span class="text-primary">{{item.servicio.descripcion}}</span></h6>
                    <h6>Cantidad: <span class="badge badge-secondary badge-marketing">{{item.cantidad}}</span></h6>
                    <h6>Observaciones: {{item.observacion}}</h6>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import ordenClinicaService from '../../../../services/ordenClinicaService';
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from '../../../../services/historiaClinicaService';

export default {
    props: ['idHistoria','finalizada'],
    data(){
        return {
            ordenes:{}
        }
    },
    methods: {
        async cargarOrdenes(){
            const response = await ordenClinicaService.showOrdenByHcAndType({
                id_historia:this.idHistoria,
                type:'medicamento'
            });
            this.ordenes= response.data;
        },
        imprimir(id_orden){
            window.open(historiaClinicaService.orden(id_orden));
        }
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            await this.cargarOrdenes();
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    }
}
</script>